<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">文件盖章</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="操作人" class="searchboxItem ci-full">
              <span class="itemLabel">操作人:</span>
              <el-input v-model="operatorName" clearable placeholder="请输入操作人" size="small"></el-input>
            </div>
            <div title="盖章日期" class="searchboxItem ci-full">
              <span class="itemLabel">盖章日期:</span>
              <el-date-picker clearable size="small" v-model="signDate" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>

            <div class="df" style="padding: 0 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="addResultfile()">新增</el-button>
              <el-button type="primary" class="bgc-bv" round @click="multiPageFile()">循环章</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="上传文件名称" align="left" show-overflow-tooltip prop="fileName" min-width="200px" />
              <el-table-column label="操作人" align="left" show-overflow-tooltip prop="operatorName" min-width="200" />

              <el-table-column label="操作时间" align="left" show-overflow-tooltip prop="operatorTime" min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.operatorTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="150px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="download(scope.row)">下载</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" :disabled="scope.row.deleted"
                    @click="lookpreview(scope.row)">预览</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="文件盖章" :visible.sync="toVoidLoding" top="5%" width="60%" @close="toVoidClose" class="toVoidLoding">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm" size="small">
        <el-form-item label="上传文件" prop="fileName">
          <el-upload class="upload-demo upload-btn" v-if="!ruleForm.fileName" :action="actionUrl" :on-error="handleError"
            :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false" :auto-upload="false">
            <el-button class="bgc-bv" size="mini">上传附件</el-button>
          </el-upload>
          <span v-else>
            {{ ruleForm.fileName }}
            <a @click="look" style="color: #5bb5ff; margin-left: 10px">预览</a>
            <a @click="reomveExl" style="color: red; margin-left: 10px">删除</a>
          </span>
        </el-form-item>
        <el-form-item label="模式" prop="modelTypeShell">
          <el-radio-group v-model="ruleForm.modelTypeShell">
            <el-radio :label="'1'">固定规则</el-radio>
            <el-radio :label="'2'">自定义规则</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="ruleForm.modelTypeShell == '1'">
          <el-form-item prop="modelType" class="form-item" label="选择章">
            <el-radio-group v-model="ruleForm.modelType" :disabled="seeTH">
              <el-radio label="1">北京晟融公章</el-radio>
              <el-radio label="2">北京晟融合同章</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="keywordName" class="form-item" label="关键字">
            <el-radio-group v-model="ruleForm.keywordName" :disabled="seeTH">
              <el-radio label="1">北京晟融</el-radio>
              <el-radio label="2">公章</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="keywordPosition" class="form-item" label="盖章位置">
            <el-radio-group v-model="ruleForm.keywordPosition" :disabled="seeTH">
              <el-radio label="1">第一页</el-radio>
              <el-radio label="3">每一页</el-radio>
              <el-radio label="2">最后一页</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="perforationPosition" class="form-item" label="是否加盖骑缝">
            <el-radio-group v-model="ruleForm.perforationPosition" @click.native="onRadioChange($event)"
              :disabled="seeTH">
              <el-radio label="1">纵向</el-radio>
              <el-radio label="2">横向</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="perforationPosition" class="form-item" label="骑缝位置" v-if="ruleForm.perforationPosition">
            <el-radio-group v-model="ruleForm.pageSealPosition" :disabled="seeTH">
              <el-radio label="1">上</el-radio>
              <el-radio label="2">中</el-radio>
              <el-radio label="3">下</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>
        <template v-if="ruleForm.modelTypeShell == '2'">
          <el-form-item label="自定义输入">
            <el-input v-model="ruleForm.signatureCode"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <div class="bottom-btn">
        <el-button @click="doAjax('look')" class="bgc-bv">预览盖章文件</el-button>
        <el-button @click="doAjax('save')" class="bgc-bv">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="文件盖章(循环章)" :visible.sync="multiPageLoading" top="1%" width="60%" @close="toVoidClose"
      class="toVoidLoding">
      <el-form :model="ruleForm2" ref="ruleForm2" :rules="rules2" label-width="100px" class="demo-ruleForm">
        <el-form-item label="上传文件" prop="fileName">
          <el-upload class="upload-demo upload-btn" v-if="!ruleForm2.fileName" :action="actionUrl" :on-error="handleError"
            :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false" :auto-upload="false">
            <el-button class="bgc-bv" size="mini">上传附件</el-button>
          </el-upload>
          <span v-else>
            {{ ruleForm2.fileName }}
            <a @click="lookMuliPage" style="color: #5bb5ff; margin-left: 10px">预览</a>
            <a @click="reomveMuliPageExl" style="color: red; margin-left: 10px">删除</a>
          </span>
        </el-form-item>
        <el-form-item label="循环章规则" prop="signature">
          <el-input v-model="ruleForm2.signature"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-btn">
        <el-button @click="multiPage('look')" class="bgc-bv">预览盖章文件</el-button>
        <el-button @click="multiPage('save')" class="bgc-bv">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="预览" :visible.sync="dialogDownload" width="50%" top="1%" class="dialogpdf" center>
      <div id="pdf-content" style="height: 800px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      operatorName: "", //姓名
      signDate: "", //日期
      toVoidLoding: false,
      ruleForm: {
        fileName: "",
        fileKey: "",
        modelTypeShell: "1", // 模式
        modelType: '1',// 选择章:1北京晟融公章 2北京晟融合同章
        keywordName: '1',// 关键字:1北京晟融 2公章
        keywordPosition: '1',// 盖章位置:1第一页 2最后一页 3每页一个
        perforationPosition: '',// 骑缝章方向:1纵向 2横向
        pageSealPosition: '2',// 骑缝位置:1上 2中 3下
        signatureCode: "", // 自定义规则
      },
      rules: {
        fileName: [{ required: true, message: "请上传文件", trigger: "blur" }],
        modelTypeShell: [
          {
            required: true,
            message: "请选择模式",
            trigger: "change",
          },
        ],
        modelType: [
          {
            required: true,
            message: "请选择章",
            trigger: "change",
          },
        ],
        keywordName: [
          {
            required: true,
            message: "请选择关键字",
            trigger: "change",
          },
        ],
        keywordPosition: [
          {
            required: true,
            message: "请选择盖章位置",
            trigger: "change",
          },
        ],
        signatureCode: [
          {
            required: true,
            message: "请输入自定义规则",
            trigger: "blur",
          },
        ],
      },
      checkboxList: [],
      dialogDownload: false,
      multiPageLoading: false,
      ruleForm2: {
        fileName: "",
        fileKey: "",
        modelType: "4",   //循环章
        signature: "",
      },
      rules2: {
        fileName: [{ required: true, message: "请上传文件", trigger: "blur" }],
        signature: [{ required: true, message: "请填写CA签章规则", trigger: "blur" }],
      },
    };
  },
  watch: {},
  created() { },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.operatorName) {
        params.operatorName = this.operatorName;
      }
      if (this.signDate) {
        params.signDateStart = this.signDate[0];
        params.signDateEnd = this.signDate[1];
      }

      this.doFetch({
        url: "/fileSign/pageList",
        params,
        pageNum,
      });
    },
    /* 下载 */
    download(row) {
      window.open(row.resultKeyDownloadUrl);
    },
    /* 上传文件 */
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      //同时赋值两个表单
      this.ruleForm.fileName = file.name;
      this.ruleForm2.fileName = file.name;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "pdf";
      const isxlsx = extension === "PDF";
      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.PDF或者.pdf的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }

      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.fileKey = result.data.fileKey;
          this.ruleForm.fileUrl = result.data.fileURL;
          this.ruleForm2.fileKey = result.data.fileKey;
          this.ruleForm2.fileUrl = result.data.fileURL;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    /* 新增 */
    addResultfile() {
      this.toVoidLoding = true;
    },
    // 是否加盖骑缝 - 单选的时候  可以在取消
    onRadioChange(e) {
      if (e.target.tagName === "INPUT") {
        if (this.ruleForm.perforationPosition === "") {
          this.ruleForm.perforationPosition = "1";
        } else {
          this.ruleForm.perforationPosition = "";
        }
      }
    },
    // 保存盖章文件 & 预览盖章文件
    doAjax(cType) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.ruleForm.modelTypeShell == 2 && !this.ruleForm.signatureCode) {
            this.$message({
              message: '自定义规则必填',
              type: "warning",
            });
            return false;
          }
          let paramr = { ...this.ruleForm };
          this.$post(
            cType != "look" ? "/fileSign/addSignFile" : "/fileSign/viewSignFile",
            paramr
          )
            .then((ret) => {
              if (ret.status == 0) {
                if (cType == "look") {
                  this.dialogDownload = true;
                  this.$nextTick(() => {
                    pdf.embed(ret.message, "#pdf-content");
                  });
                } else {
                  this.$message({
                    message: ret.message,
                    type: "success",
                  });
                  this.getData();
                  this.toVoidClose();
                }
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    /* 预览 */
    look() {
      this.dialogDownload = true;
      this.$nextTick(() => {
        pdf.embed(this.ruleForm.fileUrl, "#pdf-content");
      });
    },
    lookpreview(row) {
      this.dialogDownload = true;
      this.$nextTick(() => {
        pdf.embed(row.resultKeyUrl, "#pdf-content");
      });
    },
    /* 删除 */
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.fileKey = "";
      this.ruleForm.fileUrl = "";
    },
    /* 关闭时清除两个表单的内容 */
    toVoidClose() {
      this.ruleForm = this.$options.data().ruleForm;
      this.ruleForm2 = this.$options.data().ruleForm2;
      this.toVoidLoding = false;
      this.multiPageLoading = false;
    },
    /* 循环章 */
    multiPageFile() {
      this.multiPageLoading = true;
    },
    /* 预览上传的文件 */
    lookMuliPage() {
      this.dialogDownload = true;
      this.$nextTick(() => {
        pdf.embed(this.ruleForm2.fileUrl, "#pdf-content");
      });
    },
    /* 删除上传的文件 */
    reomveMuliPageExl() {
      this.ruleForm2.fileName = "";
      this.ruleForm2.fileKey = "";
      this.ruleForm2.fileUrl = "";
    },
    /* 盖循环章 */
    multiPage(stu) {
      // look 为预览; save为保存
      this.stu = stu;
      this.$refs.ruleForm2.validate((valid, obj) => {
        if (valid) {
          let paramr = {
            "fileKey": this.ruleForm.fileKey,
            "fileName": this.ruleForm.fileName,
            "signatureCode": this.ruleForm2.signature,
          };
          this.$post(
            this.stu == "look" ? "/fileSign/preview/multi-page" : "/fileSign/file/multi-page",
            paramr
          )
            .then((ret) => {
              if (ret.status == 0) {
                if (this.stu == "look") {
                  this.dialogDownload = true;
                  console.log(ret);
                  this.$nextTick(() => {
                    pdf.embed(ret.message, "#pdf-content");
                  });
                } else {
                  this.$message({
                    message: ret.message,
                    type: "success",
                  });
                  this.multiPageLoading = false;
                  this.getData();
                }
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.upload-btn {
  width: 5rem;
  height: 28px;
  margin-top: 6px;

  /deep/ .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .el-checkbox-group {
//   display: flex;
//   flex-direction: column;
// }
</style>
